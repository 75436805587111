import React, { useCallback, useMemo } from 'react';
import MobileStepper from '@mui/material/MobileStepper';
import { FingoDialog } from '@fingo/lib/components/dialogs';
import { useGetUser, useIsLogged, useSnackBars } from '@fingo/lib/hooks';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import QuickRegisterForm from './QuickRegisterForm';
import QuickSiiForm from './QuickSiiForm';

const steps = [
  {
    title: '¡Empecemos!',
    component: <QuickRegisterForm />,
  },
  {
    title: (
      <>
        <Typography color="common.white" align="center" variant="h4">Ingresa tus credenciales </Typography>
        <Typography color="common.white" align="center" variant="h4">visualizar tus facturas</Typography>
      </>
    ),
    component: <QuickSiiForm />,
  },
];

const StepperRegisterForm = ({ registerDialog, setRegisterDialog }) => {
  const { addAlert } = useSnackBars();
  const user = useGetUser();
  const isLogged = useIsLogged();
  const onClose = useCallback(() => {
    if (!user) {
      setRegisterDialog(false);
      return;
    }
    addAlert({
      id: 0,
      color: 'text',
      severity: 'info',
      message: 'Continua con el registro para ver tus facturas rechazadas',
    });
  }, [addAlert, setRegisterDialog, user]);
  const activeStep = useMemo(() => (user ? 1 : 0), [user]);
  const maxSteps = steps.length;
  const { component, title } = useMemo(() => steps[activeStep], [activeStep]);
  if (!user && isLogged) return null;
  return (
    <FingoDialog
      open={registerDialog}
      title={title}
      handleClose={onClose}
      PaperProps={{ sx: { borderRadius: 4, maxWidth: 500 } }}
      hideCloseButton={activeStep === 1}
    >
      {component}
      <MobileStepper
        variant="dots"
        steps={maxSteps}
        position="static"
        activeStep={activeStep}
        sx={{ justifyContent: 'center' }}
      />
    </FingoDialog>
  );
};

StepperRegisterForm.propTypes = {
  // eslint-disable-next-line react/no-unused-prop-types
  registerDialog: PropTypes.bool,
  setRegisterDialog: PropTypes.func,
};

StepperRegisterForm.defaultProps = {
  registerDialog: false,
  setRegisterDialog: () => { },
};
export default StepperRegisterForm;
