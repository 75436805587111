import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Stack from '@mui/material/Stack';
import Check from '@mui/icons-material/Check';
import Clear from '@mui/icons-material/Clear';
import { passwordCheckers } from './passwordCheckers';

const PasswordStrength = ({ password }) => {
  const validationResults = useMemo(
    () => passwordCheckers.map(({ regexChecker }) => ({
      isValid: regexChecker(password),
    })),
    [password],
  );

  const getIcon = (isValid) => (isValid ? (
    <Check size="small" color="success" />
  ) : (
    <Clear size="small" color="error" />
  ));

  return (
    <Stack width="100%" alignItems="flex-start">
      <List>
        {passwordCheckers.map(({ label }, index) => (
          <ListItem key={label} sx={{ padding: 0 }}>
            <ListItemIcon sx={{ minWidth: 0 }}>
              {getIcon(validationResults[index].isValid)}
            </ListItemIcon>
            <ListItemText primary={label} />
          </ListItem>
        ))}
      </List>
    </Stack>
  );
};

PasswordStrength.propTypes = {
  password: PropTypes.string.isRequired,
};

export default React.memo(PasswordStrength);
