import React from 'react';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import NoteAlt from '@mui/icons-material/NoteAlt';

const KobrosCollectInvoice = () => (
  <Stack
    spacing={2}
    alignItems="center"
    justifyContent="center"
  >
    <NoteAlt sx={{ fontSize: 60, color: 'secondary.main' }} />
    <Typography textAlign="center" variant="h1" marginBottom={1}>
      Marcar {' '}
      <Typography variant="h1" sx={{ color: 'secondary.main' }} component="span">
        como Pagada
      </Typography>
    </Typography>
    <Typography textAlign="center" variant="h2" sx={{ fontWeight: 'bold' }}>
      ¡Registra el pago de tus facturas!
    </Typography>
    <Typography textAlign="center">
      Es importante que ingreses la fecha de pago real de la factura,
      pues esta información alimenta el historial de pago de tu cliente.
      Una vez que marques tus facturas pagadas, las podrás revisar en tu cartera histórica.
    </Typography>
  </Stack>
);

export default KobrosCollectInvoice;
