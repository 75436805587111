import React from 'react';
import PropTypes from 'prop-types';
import dayjs from '@fingo/lib/config/dayjs';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import Avatar from '@mui/material/Avatar';
import Apartment from '@mui/icons-material/Apartment';
import { Stack } from '@mui/system';
import traceToLabel from '@fingo/lib/components/traces/traceText';
import SiiIcon from '@fingo/lib/components/icons/SiiIcon';
import InvoiceStatusStepper from './invoiceStatus';

const iconColor = {
  1: 'error.main',
  2: 'error.main',
  3: 'error.main',
  4: 'success.main',
  5: 'primary.main',
  6: 'primary.main',
  7: 'success.main',
  8: 'primary.main',
};

const TraceStatus = ({ invoice }) => {
  const { traces, providerTrace } = invoice;
  const steps = () => {
    let functionRun = 0;
    /* We sort the events by the dayjs of occurrence */
    const sortedEvents = traces
      .concat(providerTrace)
      .sort((a, b) => dayjs(a.eventTime) - dayjs(b.eventTime))
      .map((event) => {
        const trace = traceToLabel(
          invoice,
          event.eventCode || event.status.code,
          event,
        );
        trace.time = event.eventTime;
        trace.comments = event.comments;
        trace.code = event.eventCode || event.status.code;
        trace.color = iconColor[trace.code] || trace.color;
        if (typeof trace.label === 'function') {
          trace.label = trace.label(functionRun);
          functionRun += 1;
        }
        return trace;
      });
    const fixedEvents = sortedEvents.reduce((acc, item) => {
      const date = dayjs(item.time);
      const dayEvent = date.format('DD MMMM YYYY');

      if (!acc[dayEvent]) {
        acc[dayEvent] = [];
      }
      acc[dayEvent].push(item);
      return acc;
    }, {});
    return fixedEvents;
  };
  if (!traces.length && !providerTrace.length) {
    return <InvoiceStatusStepper invoice={invoice} />;
  }
  return (
    <Stack sx={{ width: '100%', marginTop: '-15px' }}>
      {Object.keys(steps()).map((date) => (
        <>
          <Typography
            sx={{
              fontSize: '14px !important',
              fontWeight: 'medium',
              textAlign: 'justify',
            }}
          >
            {date}
          </Typography>
          <Stepper
            key={date}
            id={date}
            orientation="vertical"
            sx={{
              width: '100%',
              '.MuiStepConnector-line': {
                display: 'none',
              },
            }}
          >
            {steps()[date].map(
              (
                { label, time, comments, isProviderInfo, code, color },
                index,
              ) => (
                <Tooltip
                  key={`${index + 1} ${code}`}
                  arrow
                  title={comments || ''}
                >
                  <Step
                    key={`${date} ${code} ${index + 1}`}
                    sx={{
                      mb: index === steps()[date].length - 1 ? '0px' : '-5px',
                    }}
                  >
                    <StepLabel
                      icon={(
                        <Avatar
                          sx={{ backgroundColor: color, width: 30, height: 30 }}
                        >
                          {isProviderInfo ? (
                            <Apartment color="common.white" fontSize="11px" />
                          ) : (
                            <SiiIcon
                              sx={{ color: 'white', fontSize: '18px' }}
                            />
                          )}
                        </Avatar>
                      )}
                      style={{ display: 'flex', flexDirection: 'row' }}
                    >
                      <Typography
                        sx={{
                          fontSize: '12px',
                          fontWeight: 'medium',
                          textAlign: 'justify',
                          color: 'gray.main',
                        }}
                      >
                        {dayjs(time).format('HH:mm')} hrs
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: '14px !important',
                          fontWeight: 'light',
                        }}
                      >
                        {label}
                      </Typography>
                    </StepLabel>
                  </Step>
                </Tooltip>
              ),
            )}
          </Stepper>
        </>
      ))}
    </Stack>
  );
};

TraceStatus.propTypes = {
  invoice: PropTypes.shape({
    siiStatus: PropTypes.string.isRequired,
    dateIssued: PropTypes.objectOf(dayjs).isRequired,
    traces: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        eventCode: PropTypes.string,
      }),
    ).isRequired,
    creditNotes: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        folio: PropTypes.number,
      }),
    ),
    providerTrace: PropTypes.arrayOf(
      PropTypes.shape({
        code: PropTypes.string,
        status: PropTypes.shape({
          code: PropTypes.string,
          status: PropTypes.string,
        }),
      }),
    ).isRequired,
    providerRejectionReasons: PropTypes.arrayOf(
      PropTypes.shape({
        code: PropTypes.string,
        comments: PropTypes.string,
        rejectionCode: PropTypes.shape({
          reason: PropTypes.string,
          code: PropTypes.string,
        }),
        reason: PropTypes.string,
      }),
    ).isRequired,
    folio: PropTypes.string.isRequired,
    loanedTo: PropTypes.string,
    binnacleSet: PropTypes.arrayOf(
      PropTypes.shape({
        message: PropTypes.string,
      }),
    ).isRequired,
    invoicedetail: PropTypes.shape({
      id: PropTypes.string,
      purchaseOrderFolio: PropTypes.string,
    }),
  }).isRequired,
};

export default TraceStatus;
