import React, { useState } from 'react';
import { useMutation } from '@apollo/client';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { PasswordInput, ValidationTextFieldInput } from '@fingo/lib/components/inputs';
import { REGISTER_USER } from '@fingo/lib/graphql';
import { formatRut, getFormFieldError } from '@fingo/lib/helpers';
import { LoadingButton } from '@mui/lab';
import styled from 'styled-components';
import Cookies from 'universal-cookie';
import { Redirect } from 'react-router-dom';
import { useBooleanState } from '@fingo/lib/hooks';
import { getSerializedSearch } from '@fingo/lib/helpers/url-filter-translator';
import { FingoLogo } from '@fingo/lib/assets';
import { Box } from '@mui/system';
import { PasswordStrength } from '../utils';
import { useLogin } from '../../hooks';
import { isPasswordValid } from '../utils/passwordCheckers';

const RegisterTextField = styled(ValidationTextFieldInput)(() => ({
  width: '100%',
}));

const QuickRegisterForm = () => {
  const [formData, setFormData] = useState({
    password: {
      value: '',
      error: '',
    },
    email: {
      value: '',
      error: '',
    },
  });
  const [formError, setFormError] = useState('');
  const [loginGql, loadingLogin, errorForm] = useLogin(
    formData.email.value.toLowerCase(),
    formData.password.value,
  );
  const cookies = new Cookies();
  const [registerUser, { loading: loadingRegisterUser }] = useMutation(REGISTER_USER, {
    variables: {
      email: formData.email.value.toLowerCase(),
      password: formData.password.value,
      utmCampaign: cookies.get('utm_campaign') || null,
    },
    onError: (error) => {
      switch (error.message) {
        case 'user already exist':
          setFormError('El correo ya está registrado');
          break;
        case 'This password is too common.':
          setFormError('Contraseña muy común');
          break;
        default:
          setFormError('Error al registrarse');
          break;
      }
    },
    onCompleted: loginGql,
  });

  const handleChange = (e) => {
    let { value } = e.target;
    const { name, type } = e.target;
    if (name === 'companyRut') value = formatRut(value);
    const fieldError = getFormFieldError(value, type, name);
    if (fieldError.isError) {
      setFormData((oldState) => ({
        ...oldState,
        [name]: { ...oldState[name], error: fieldError.message, value },
      }));
    } else {
      setFormData((oldState) => ({
        ...oldState,
        [name]: { ...oldState[name], error: '', value },
      }));
    }
  };
  const isButtonReady = () => {
    for (let i = 0; i < Object.values(formData).length; i += 1) {
      const element = Object.values(formData)[i];
      if (element.value === '' || element.error !== '') {
        setFormError('Por favor completa todos los campos');
        return false;
      }
    }
    if (!isPasswordValid(formData.password.value)) {
      setFormError('La contraseña no cumple con los requisitos');
      return false;
    }
    return true;
  };
  const [redirectLogin, toggleRedirect] = useBooleanState(false);

  return (
    <Grid
      sx={{
        width: { xs: 250, sm: 400 },
        pt: 1,
        pb: 0,
        px: { xs: 0, md: 4 },
        mr: 'auto',
        ml: 'auto',
      }}
      elevation={4}
    >
      <Stack alignItems="center" spacing={2} width="100%">
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <img src={FingoLogo} alt="Fingo Logo" style={{ width: '200px', alignSelf: 'center' }} />
        </Box>
        <Typography
          variant="h6"
          align="center"
          sx={
          { mb: 2 }
        }
        >
          Regístrate en Fingo para que puedas ver todas tus
          facturas rechazadas y mucho más
        </Typography>
        <form
          onSubmit={async (event) => {
            event.preventDefault();
            if (isButtonReady()) {
              await registerUser();
            }
          }}
          id="quickLoginForm"
        >
          <Stack alignItems="center" spacing={2} width="100%">
            <RegisterTextField
              name="email"
              validationtype="email"
              type="email"
              label="Email"
              variant="standard"
              value={formData.email.value}
              onChange={handleChange}
            />
            <PasswordInput
              name="password"
              label="Contraseña"
              variant="standard"
              value={formData.password.value}
              onChange={handleChange}
              error={!!errorForm?.password}
              helperText={errorForm.password}
              sx={{
                width: '100%',
              }}
            />
            {Boolean(formError) && (
            <Typography
              variant="caption"
              component="div"
              color="error"
              align="center"
              gutterBottom
            >
              {formError}
            </Typography>
            )}
            <PasswordStrength password={formData.password.value} />
            <LoadingButton
              id="quick-register-user"
              variant="contained"
              color="primary"
              size="small"
              type="submit"
              loading={loadingLogin || loadingRegisterUser}
              sx={{
                width: '50%',
              }}
            >
              Crear cuenta
            </LoadingButton>
            <Button
              id="quick-register-client"
              variant="text"
              size="small"
              onClick={toggleRedirect}
            >
              Ya tengo cuenta
            </Button>
            {redirectLogin ? (
              <Redirect to={{
                pathname: '/login',
                search: `next=/app/sales/accountable?${getSerializedSearch({ siiStatus: ['Rejected'] })}`,
              }}
              />
            ) : <></>}
          </Stack>
        </form>
      </Stack>
    </Grid>
  );
};

export default QuickRegisterForm;
