import React from 'react';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Mail from '@mui/icons-material/Mail';

const KobrosSendCollectionMail = () => (
  <Stack
    spacing={2}
    alignItems="center"
    justifyContent="center"
  >
    <Mail sx={{ fontSize: 60, color: 'secondary.main' }} />
    <Typography textAlign="center" variant="h1" marginBottom={1}>
      Enviar nuevo {' '}
      <Typography variant="h1" sx={{ color: 'secondary.main' }} component="span">
        Mail de Cobranza
      </Typography>
    </Typography>
    <Typography textAlign="center" variant="h2" sx={{ fontWeight: 'bold' }}>
      ¡Con koBros puedes enviar correos con un solo click!
    </Typography>
    <Typography textAlign="center">
      Elige el template que quieres usar,
      adjunta los archivos que has subido a la plataforma y envía el
      correo con la información de pago a todos los contactos que necesites.
      ¡Recuerda que el correo se enviará con copia a ti,
      y que puedes personalizarlo con el logo de tu empresa!
    </Typography>
  </Stack>
);

export default KobrosSendCollectionMail;
