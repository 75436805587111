import React from 'react';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { SandClock } from '@fingo/lib/assets/icons/tyc';

const KobrosTooMuchTimeWaiting = () => (
  <Stack
    spacing={2}
    alignItems="center"
    justifyContent="center"
  >
    <Box width={40}>
      <SandClock />
    </Box>
    <Typography>
      <Typography textAlign="center" variant="h1" marginBottom={1} component="span">
        Te {' '}
      </Typography>
      <Typography variant="h1" sx={{ color: 'secondary.main' }} component="span">
        redirigiremos {' '}
      </Typography>
      <Typography textAlign="center" variant="h1" marginBottom={1} component="span">
        en unos instantes
      </Typography>
    </Typography>
    <Typography textAlign="center" variant="h2" sx={{ fontWeight: 'bold' }}>
      Estamos cargando todas tus facturas
    </Typography>
    <Typography textAlign="center">
      Si quieres haz click en el botón de
      &quot;Solicitar más info&quot;
      para que te podamos contactar y mostrar toda la funcionalidad que koBros tiene para ti
    </Typography>
  </Stack>
);

export default KobrosTooMuchTimeWaiting;
