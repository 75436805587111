import React from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@mui/styles/makeStyles';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';

const useStyles = makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
});

const ErrorPage = ({ message, full }) => {
  const classes = useStyles();
  return (
    <Container maxWidth="md" className={classes.container} style={full ? { height: '90vh' } : null}>
      <Paper style={{ margin: '1rem' }}>
        <Typography variant="h6" align="center">Algo no salió como esperábamos</Typography>
        <Typography align="center">{message}</Typography>
      </Paper>
    </Container>
  );
};

ErrorPage.propTypes = {
  message: PropTypes.string,
  full: PropTypes.bool,
};

ErrorPage.defaultProps = {
  message: 'Por favor intenta nuevamente o contáctate con nosotros a contacto@fingo.cl o al +56 9 8901 0221',
  full: false,
};

export default ErrorPage;
